/* eslint react/jsx-props-no-spreading: "off" */
/* eslint react/jsx-wrap-multilines: "off" */
/* eslint no-console: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { trackPromise } from 'react-promise-tracker';
import FlipMove from 'react-flip-move';
import Survey from './Survey';
import ErrorMessage from './ErrorMessage';
import T from './Translate';
import FilterButtonsContainer from './FilterButtons/FilterButtonsContainer';
import { getAuthToken, shouldRefreshApp } from '../service/Jwt';
import { refreshPageWithoutStatusParam } from '../shared/Refresh';
import loadedFromBackOrForward from '../shared/NavigationHelper';
import { getStudies, getProfileUrl } from '../service/Studies';
import StudiesHistory from './StudiesHistory';
import './Studies.scss';
import { ReactComponent as NoSurveysIcon } from '../shared/NoSurveys.svg';
import ProfilePopup from './ProfilePopup';

class Studies extends React.Component {
  MIN_IN_MILLIS = 60000;

  constructor(props) {
    super(props);
    this.handleWebcamChange = this.handleWebcamChange.bind(this);
    this.handlePiiChange = this.handlePiiChange.bind(this);
    this.handleShowProfileChange = this.handleShowProfileChange.bind(this);
    this.isLocked = this.isLocked.bind(this);
    this.reachedMaxTime = false;
    this.hideFilterButtons = props.hideFilterButtons;
    this.webcamEnabled = !props.hideFilterButtons;
    this.piiEnabled = !props.hideFilterButtons;
    this.maxSurveyNumber = props.maxSurveyNumber;

    this.manuallyRefreshed = false;
    this.state = {
      studies: [],
      errorOccurred: false,
      onLoaded: props.onLoaded,
      profileUrl: '',
      showProfile: false,
      serviceUnavailable: false,
      profilePercentage: 0,
    };
  }

  componentDidMount() {
    this.updateStudiesState();
    this.timerID = setInterval(() => {
      this.checkVisibilityAndUpdate();
    }, this.MIN_IN_MILLIS);

    if (loadedFromBackOrForward() && !this.manuallyRefreshed) {
      this.manuallyRefreshed = true;
      this.updateStudiesState();
    }

    this.updateProfileUrl();

    document.addEventListener(
      'visibilitychange',
      this.visibilityChange.bind(this),
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChange.bind(this),
    );
    clearInterval(this.timerID);
  }

  handleWebcamChange() {
    this.webcamEnabled = !this.webcamEnabled;
    this.updateStudiesState();
  }

  handlePiiChange() {
    this.piiEnabled = !this.piiEnabled;
    this.updateStudiesState();
  }

  handleShowProfileChange() {
    const { showProfile } = this.state;
    this.setState({ showProfile: !showProfile });
  }

  updateStudiesState() {
    const { webcamEnabled, piiEnabled, maxSurveyNumber } = this;

    const authtoken = getAuthToken();
    if (shouldRefreshApp(authtoken)) {
      refreshPageWithoutStatusParam();
    }
    trackPromise(
      getStudies(authtoken, webcamEnabled, piiEnabled, maxSurveyNumber)
        .then((data) => {
          this.setState({ studies: data });
          const { onLoaded } = this.state;
          onLoaded();
        })
        .catch((error) => {
          if (error.response && error.response.status === 503) {
            this.setState({ serviceUnavailable: true });
          } else {
            if (this.airbrake) {
              // to send error to Airbrake front end error reporting
              this.airbrake.notify(error);
            }
            this.setState({ errorOccurred: true });
          }

          const { onLoaded } = this.state;
          onLoaded();
        }),
    );
  }

  // If tab is not visible don't reload till tab is back in focus
  checkVisibilityAndUpdate() {
    if (document.visibilityState !== 'hidden') {
      this.updateStudiesState();
    } else {
      this.reachedMaxTime = true;
      clearInterval(this.timerID);
    }
  }

  visibilityChange() {
    if (document.visibilityState !== 'hidden' && this.reachedMaxTime) {
      this.reachedMaxTime = false;

      this.updateStudiesState();
      clearInterval(this.timerID);
      this.timerID = setInterval(() => {
        this.checkVisibilityAndUpdate();
      }, this.MIN_IN_MILLIS);
    }
  }

  updateProfileUrl() {
    const authtoken = getAuthToken();
    trackPromise(
      getProfileUrl(authtoken)
        .then((response) => {
          this.setState({
            profileUrl: response.data.profile_url,
            profilePercentage: response.data.profile_completion_percentage,
          });
        })
        .catch((error) => {
          console.log('Could not fetch profile URL', error);
        }),
    );
  }

  isLocked() {
    const { studies } = this.state;
    return studies.some((survey) => survey.flags.includes('locked'));
  }

  render() {
    const {
      studies,
      errorOccurred,
      profileUrl,
      showProfile,
      serviceUnavailable,
      profilePercentage,
    } = this.state;

    const { loaded } = this.props;
    const {
      webcamEnabled,
      piiEnabled,
      maxSurveyNumber,
      handleShowProfileChange,
      isLocked,
    } = this;

    if (serviceUnavailable) {
      return (
        <ErrorMessage
          title="Under Maintenance"
          message="We are performing planned maintenance. Please bear with us,
          everything will be up and running soon"
        />
      );
    }

    if (errorOccurred) {
      return (
        <ErrorMessage
          title="An Error has occured"
          message="Unable to show surveys, something went wrong. Please try again later
          or contact our support"
        />
      );
    }

    const studyItems = studies.map((study) => (
      <Survey
        key={study.id}
        category={study.category}
        categoryImage={study.categoryImage}
        formattedReward={study.formattedReward}
        reward={study.reward}
        loi={study.loi}
        flags={study.flags}
        url={study.url}
        lockedIcon={study.lockedIcon}
        lockedInfo={study.lockedInfo}
      />
    ));

    let surveyContent = (
      <div>
        <FlipMove
          type="ul"
          className="ow-survey-listing"
          duration={500}
          easing="ease"
          enterAnimation="none"
          leaveAnimation="none"
        >
          {(maxSurveyNumber && studyItems.slice(0, maxSurveyNumber))
          || studyItems}
        </FlipMove>
        <StudiesHistory errorOccurred={errorOccurred} />
      </div>
    );

    const profileIframe = (
      <ProfilePopup
        onClose={handleShowProfileChange}
        showProfile={showProfile}
        profileUrl={profileUrl}
      />
    );

    if (studyItems.length === 0) {
      surveyContent = (
        <div className="ow-no-surveys-container">
          <NoSurveysIcon />
          <h1 className="ow-title">Sorry! There&apos;s no surveys available</h1>
          <p>
            <T>Check back later</T>
          </p>
        </div>
      );
    }

    return (
      <div>
        {!this.hideFilterButtons && (
          <FilterButtonsContainer
            webcam={webcamEnabled}
            onWebcamChange={this.handleWebcamChange}
            pii={piiEnabled}
            onPiiChange={this.handlePiiChange}
            onShowProfileChange={this.handleShowProfileChange}
            showProfile={showProfile}
            hideProfileButton={profileUrl === ''}
            profilePercentage={profilePercentage}
          />
        )}

        {isLocked() && (
          <div className="ow-vpn-warning-message">
            <h3>Please use a valid internet connection</h3>
            <p>Using a VPN, proxy or TOR prevents you accessing the surveys</p>
          </div>
        )}
        {loaded && <div>{surveyContent}</div>}

        <div hidden={!showProfile}>{profileIframe}</div>
      </div>
    );
  }
}

Studies.propTypes = {
  onLoaded: PropTypes.func,
  hideFilterButtons: PropTypes.bool,
  maxSurveyNumber: PropTypes.number,
  loaded: PropTypes.bool,
};

Studies.defaultProps = {
  onLoaded: () => 0,
  hideFilterButtons: false,
  maxSurveyNumber: null,
  loaded: true,
};

export default Studies;
