import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const WhyToolTip = ({ toggleShowProfile }) => {
  const messageRef = useRef();
  const toolTipRef = useRef();

  const positionMessage = () => {
    if (!messageRef.current || !toolTipRef.current) return;

    const message = messageRef.current;
    const iconRect = toolTipRef.current.getBoundingClientRect();
    const messageRect = message.getBoundingClientRect();

    if (window.innerWidth < 600) {
      message.style.right = '0%';
      message.style.left = 'auto';
      return;
    }

    const messageOverflowing = iconRect.right + messageRect.width >= window.innerWidth;

    message.style.left = messageOverflowing ? 'auto' : '0%';
    message.style.right = messageOverflowing ? '0%' : 'auto';
  };

  useEffect(() => {
    positionMessage();
    window.addEventListener('resize', positionMessage);
    window.addEventListener('orientationchange', positionMessage);
    return () => {
      window.removeEventListener('resize', positionMessage);
      window.removeEventListener('orientationchange', positionMessage);
    };
  }, [toolTipRef, messageRef]);

  return (
    <div className="ow-why-tooltip" ref={toolTipRef}>
      <p className="ow-why-tooltip-message">Why?</p>

      <div className="ow-why-tooltip-contents" ref={messageRef}>
        <p>
          A completed profile ensures you get relevant surveys and we only use
          this data to match you to surveys.
        </p>
        <p>
          It means students won’t be asked what it’s like to be a CEO or someone
          in London isn’t asked about life in New York
        </p>

        <button
          className="ow-why-tooltip-button"
          onClick={() => {
            toggleShowProfile();
          }}
          type="button"
        >
          Update Profile
        </button>
      </div>
    </div>
  );
};

WhyToolTip.propTypes = {
  toggleShowProfile: PropTypes.func.isRequired,
};

export default WhyToolTip;
