function loadedFromBackOrForward() {
  if (window.performance) {
    const navEntries = window.performance.getEntriesByType('navigation');

    // use v2 navigation API
    let navIsBackOrForward = navEntries.length > 0
      && navEntries[0].type === 'back_forward';
    if (navIsBackOrForward) {
      return navIsBackOrForward;
    }

    // use v1 navigation API
    navIsBackOrForward = window.performance.navigation
      && window.performance.navigation.type
      === window.performance.navigation.TYPE_BACK_FORWARD;

    return navIsBackOrForward;
  }

  return undefined;
}

export default loadedFromBackOrForward;
