import Gettext from 'node-gettext';
import frenchTranslations from '../locale/fr_translations.json';
import italianTranslations from '../locale/it_translations.json';

const countryCodeMappings = {
  USA: 'EN',
  CAN: 'EN',
  AUS: 'EN',
  FRA: 'FR',
  ITA: 'IT',
};

function getTranslatorClient() {
  const gt = new Gettext();

  // Add all translations files to getText here
  gt.addTranslations('FR', 'messages', frenchTranslations);
  gt.addTranslations('IT', 'messages', italianTranslations);

  return gt;
}

export { getTranslatorClient };

export function localeFromCode(code) {
  if (code in countryCodeMappings) {
    return countryCodeMappings[code];
  }

  // Default to EN if we do not have any mappings for specified code
  return 'EN';
}
