import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import FilterButton from './FilterButton';
import T from '../Translate';
import { ReactComponent as WebcamIcon } from '../../shared/Camera.svg';
import { ReactComponent as PiiIcon } from '../../shared/PersonalInfo.svg';
import './FilterButtonsContainer.scss';
import ThemeContext from '../../shared/theme-context';
import WhyToolTip from './WhyTooltip';

class FilterButtonsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.toggleWebcam = this.toggleWebcam.bind(this);
    this.togglePii = this.togglePii.bind(this);
    this.showProfile = this.toggleShowProfile.bind(this);
    this.toggleShowProfile = this.toggleShowProfile.bind(this);
  }

  toggleWebcam() {
    const { onWebcamChange } = this.props;
    onWebcamChange();
  }

  togglePii() {
    const { onPiiChange } = this.props;
    onPiiChange();
  }

  toggleShowProfile() {
    const { onShowProfileChange } = this.props;
    onShowProfileChange();
  }

  webcamToolTip() {
    const { webcam } = this.props;

    return webcam ? (
      <T>Hide webcam surveys</T>
    ) : (
      <T>Show webcam surveys</T>
    );
  }

  piiToolTip() {
    const { pii } = this.props;

    return pii ? (
      <T>Hide surveys that require personal information</T>
    ) : (
      <T>Show surveys that require personal information</T>
    );
  }

  profileIconColour() {
    const { button_symbol_color: profileColour } = this.context;
    return profileColour;
  }

  profileToolTip() {
    return this.showProfile ? (
      <T>Return to surveys</T>
    ) : (
      <T>Edit your profile data</T>
    );
  }

  render() {
    const {
      webcam,
      pii,
      profilePercentage,
    } = this.props;

    return (
      <div className="ow-toggle-button-container">
        <div>
          {!!profilePercentage && (
            <div className="ow-profile-container">
              <div className="ow-profile-completion-message">
                <p className="">
                  <span className="ow-large-displays-text">
                    Your
                    {' '}
                  </span>
                  Survey Profile
                  <span className="ow-large-displays-text">
                    {' '}
                    is
                  </span>
                  {' '}
                  <span className="ow-profile-completion-percentage">
                    {profilePercentage}
                    %
                    <span className="ow-large-displays-text">
                      {' '}
                      Complete
                    </span>
                  </span>
                </p>
                <hr />
                <button
                  className="ow-profile-update-button"
                  type="button"
                  onClick={() => {
                    this.toggleShowProfile();
                  }}
                >
                  <T>Update</T>
                </button>
              </div>
              <WhyToolTip toggleShowProfile={this.toggleShowProfile} />
            </div>
          )}
        </div>

        <div>
          <Tooltip title={this.webcamToolTip()}>
            <FilterButton
              className={`ow-filter-button ${!webcam && 'filtered'}`}
              onClick={() => {
                this.toggleWebcam();
              }}
              variant="contained"
              colour="primary"
            >
              <WebcamIcon />
            </FilterButton>
          </Tooltip>
          <Tooltip title={this.piiToolTip()}>
            <FilterButton
              className={`ow-filter-button ${!pii && 'filtered'}`}
              style={{
                marginLeft: '16px',
              }}
              onClick={() => {
                this.togglePii();
              }}
              variant="contained"
              colour="primary"
            >
              <PiiIcon />
            </FilterButton>
          </Tooltip>
          {/* {!hideProfileButton && (
            <Tooltip title={<T>Edit your profile data</T>}>
              <FilterButton
                className="ow-filter-button"
                style={{
                  marginLeft: '20px',
                }}
                onClick={() => {
                  this.toggleShowProfile();
                }}
                variant="contained"
                colour="primary"
              >
                <ProfileIcon />
              </FilterButton>
            </Tooltip> */}
          {/* )} */}
        </div>
      </div>
    );
  }
}

FilterButtonsContainer.propTypes = {
  pii: PropTypes.bool,
  webcam: PropTypes.bool,
  onShowProfileChange: PropTypes.func.isRequired,
  onWebcamChange: PropTypes.func.isRequired,
  onPiiChange: PropTypes.func.isRequired,
  profilePercentage: PropTypes.number,
};

FilterButtonsContainer.defaultProps = {
  webcam: false,
  pii: false,
  profilePercentage: 0,
};

export default FilterButtonsContainer;
FilterButtonsContainer.contextType = ThemeContext;
