/* eslint import/no-dynamic-require: "off" */
/* eslint global-require: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { FaRetweet } from 'react-icons/fa';
import { BsEyeSlash } from 'react-icons/bs';
import {
  HotTooltip,
  PiiTooltip,
  RecontactTooltip,
  ExplicitTooltip,
  WebcamTooltip,
} from './tooltips';
import { ReactComponent as PiiIcon } from '../shared/pii.svg';
import { ReactComponent as GoFlame } from '../shared/flame.svg';
import { ReactComponent as ClockIcon } from '../shared/Clock.svg';
import { ReactComponent as ExplicitIcon } from '../shared/explicit.svg';
import { ReactComponent as ForwardIcon } from '../shared/arrow-ios-forward.svg';
import { ReactComponent as WebcamIcon } from '../shared/Camera.svg';
import './Survey.scss';
import ThemeContext from '../shared/theme-context';
import T from './Translate';
import { PanelBrandSettingsContext } from './BrandSettings';

function isHot(flags) {
  return flags.includes('hot');
}

function isWebcam(flags) {
  return flags.includes('webcam');
}

function isRecontact(flags) {
  return flags.includes('recontact');
}

function isNoRewardPrescreener(flags, reward) {
  return flags.includes('screener') && reward === 0;
}

function isPII(flags) {
  return flags.includes('collects_pii');
}

function isExplicitContent(flags) {
  return flags.includes('explicit_content');
}

function isSensitiveContent(flags) {
  return flags.includes('sensitive_content');
}

function isLocked(flags) {
  return flags.includes('locked');
}

function formatTime(statusTime, timeNow) {
  const timeObj = new Date(statusTime);
  if (timeObj.getDate() === timeNow.getDate()) {
    return ` at ${timeObj.getHours()}:${timeObj.getMinutes()}`;
  }

  if (timeObj.getDate() === timeNow.getDate() - 1) {
    return ' yesterday';
  }

  return ` on ${timeObj.getDate()}/${
    timeObj.getMonth() + 1
  }/${timeObj.getFullYear()}`;
}

class Survey extends React.Component {
  ctrlButtonPressed = false;

  constructor(props) {
    super(props);
    this.surveyClicked = false;
  }

  onMouseDownHandler(event) {
    if (event.button === 0 && this.ctrlButtonPressed) {
      event.preventDefault();
    }
  }

  onKeyDownHandler(event) {
    if (event.ctrlKey || event.metaKey) {
      this.ctrlButtonPressed = true;
    }
  }

  onKeyUpHandler(event) {
    if (event.ctrlKey || event.metaKey) {
      this.ctrlButtonPressed = false;
    }
  }

  static onContextMenuHandler(event) {
    event.preventDefault();
  }

  // Disables link for a second when clicked
  onClickLink(event) {
    if (this.surveyClicked === false) {
      this.surveyClicked = true;
      setTimeout(() => {
        if (this.surveyClicked) {
          this.surveyClicked = false;
        }
      }, 1000);
    } else {
      event.preventDefault();
    }
  }

  render() {
    const {
      category,
      loi,
      flags,
      url,
      formattedReward,
      reward,
      status,
      statusUpdatedAt,
    } = this.props;
    return (
      <a
        className="ow ow-survey"
        href={url}
        onClick={(e) => this.onClickLink(e)}
        onKeyDown={(e) => this.onKeyDownHandler(e)}
        onKeyUp={(e) => this.onKeyUpHandler(e)}
        onContextMenu={(e) => Survey.onContextMenuHandler(e)}
        onMouseDown={(e) => this.onMouseDownHandler(e)}
        style={{
          opacity: `${isLocked(flags) ? 0.6 : 1}`,
          pointerEvents: `${isLocked(flags) ? 'none' : 'all'}`,
        }}
      >
        <PanelBrandSettingsContext.Consumer>
          {(context) => (
            <div>
              <div className="ow ow-survey-image-container">
                {status && (
                  <div className="ow-status">
                    <div className="ow-status-text">
                      {status}
                      {statusUpdatedAt && status !== 'terminated' && (
                        <span>{formatTime(statusUpdatedAt, new Date())}</span>
                      )}
                    </div>
                  </div>
                )}

                {isSensitiveContent(flags) && (
                  <div className="survey-message-container">
                    <BsEyeSlash size="24" />
                    <span className="survey-message-title">
                      <T>Sensitive content</T>
                    </span>
                    <span className="survey-message-message">
                      <T>
                        This survey contains content that may be uncomfortable
                        for some people. User discretion is advised
                      </T>
                    </span>
                  </div>
                )}
                {isNoRewardPrescreener(flags, reward) && (
                  <div className="survey-message-container">
                    <span className="survey-message-title">
                      <T>Profile Information</T>
                    </span>
                    <span className="survey-message-message">
                      <T>
                        Tell us about yourself so we can find the right surveys
                        for you
                      </T>
                    </span>
                  </div>
                )}
              </div>

              <div className="ow-survey-icon-container">
                {isHot(flags) && (
                  <HotTooltip
                    arrow
                    placement="bottom"
                    className="ow-survey-tooltip"
                    color={context.primary_colour}
                  >
                    <div className="ow-highlight-icon ow-hot-icon">
                      <div data-tip="true" className="ow-hot-icon-container">
                        <GoFlame size={20} />
                      </div>
                    </div>
                  </HotTooltip>
                )}
                {isRecontact(flags) && (
                  <RecontactTooltip
                    arrow
                    placement="bottom"
                    color={context.primary_colour}
                  >
                    <div className="ow ow-highlight-icon ow-recontact-icon">
                      <div className="ow-retweet-icon-container">
                        <FaRetweet height={20} width={20} />
                      </div>
                    </div>
                  </RecontactTooltip>
                )}
                {isPII(flags) && (
                  <PiiTooltip
                    arrow
                    placement="bottom"
                    className="ow-survey-tooltip"
                    color={context.primary_colour}
                  >
                    <div className="ow ow-highlight-icon ow-pii-icon">
                      <div className="ow-pii-icon-container">
                        <PiiIcon size={24} />
                      </div>
                    </div>
                  </PiiTooltip>
                )}

                {isWebcam(flags) && (
                  <WebcamTooltip
                    arrow
                    placement="bottom"
                    className="ow-survey-tooltip"
                    color={context.primary_colour}
                  >
                    <div className="ow ow-highlight-icon ow-webcam-icon">
                      <div className="ow-pii-icon-container">
                        <WebcamIcon size={24} />
                      </div>
                    </div>
                  </WebcamTooltip>
                )}

                {isExplicitContent(flags) && (
                  <ExplicitTooltip
                    arrow
                    placement="bottom"
                    color={context.primary_colour}
                  >
                    <div className="ow-explicit-icon">
                      <ExplicitIcon size={24} />
                    </div>
                  </ExplicitTooltip>
                )}
              </div>
            </div>
          )}
        </PanelBrandSettingsContext.Consumer>
        <ThemeContext.Consumer>
          {(context) => (
            <div
              className="ow-survey-info"
              style={{
                background: `${context.survey_info_bkgnd_color}`,
                borderBottomLeftRadius: `${context.survey_border_radius}`,
                borderBottomRightRadius: `${context.survey_border_radius}`,
              }}
            >
              <div className="ow-survey-info-contents">
                {status.toLowerCase() !== 'declined'
                  && !isNoRewardPrescreener(flags, reward)
                  && formattedReward && (
                    <div className="ow-reward-container">
                      <div className="ow-reward">{formattedReward}</div>
                      <p className="ow-earn-cash">Earn Cash</p>
                      <ForwardIcon className="ow-forward-icons" />
                    </div>
                )}
                <div>
                  {!isNoRewardPrescreener(flags, reward) && (
                    <span className="ow-category">{category}</span>
                  )}
                  <div className="ow-duration">
                    <div className="ow-estimated-duration">
                      <span className="ow-duration-icon">
                        <ClockIcon />
                      </span>
                      {loi}
                      {' '}
                      mins
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ThemeContext.Consumer>
      </a>
    );
  }
}

Survey.propTypes = {
  category: PropTypes.string,
  loi: PropTypes.string,
  flags: PropTypes.arrayOf(String),
  url: PropTypes.string,
  formattedReward: PropTypes.string,
  reward: PropTypes.number,
  status: PropTypes.string,
  statusUpdatedAt: PropTypes.string,
};

Survey.defaultProps = {
  category: '',
  loi: '',
  flags: [''],
  url: '',
  formattedReward: '',
  reward: 0,
  status: '',
  statusUpdatedAt: '',
};

export default Survey;
export { formatTime };
