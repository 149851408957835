/* eslint-disable no-console */

import React from 'react';
import PropTypes from 'prop-types';
import { getTranslatorClient } from '../service/GetTextHelper';

export const LocaleContext = React.createContext({ getTranslation: (text) => text });

export default function LocaleProvider({ locale, children }) {
  const client = getTranslatorClient();
  client.setLocale(locale);
  client.on('error', (error) => {
    if (client.locale !== 'EN') {
      console.log('No translation found', error);
    }
  });

  const getTranslation = React.useCallback((text) => client.gettext(text), [client]);

  const context = React.useMemo(() => ({ getTranslation }), [getTranslation]);

  return (
    <LocaleContext.Provider value={context}>
      {children}
    </LocaleContext.Provider>
  );
}

LocaleProvider.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
