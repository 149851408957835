class Study {
  constructor(data) {
    Object.assign(this, data);
  }

  valid() {
    if (!this.id) {
      return false;
    }
    return true;
  }
}

export default Study;
