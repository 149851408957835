function removeParam(key, sourceURL) {
  let rtn = sourceURL.split('?')[0];
  let param;
  let paramsArray = [];
  const queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    paramsArray = queryString.split('&');
    for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
      [param] = paramsArray[i].split('=');
      if (param === key) {
        paramsArray.splice(i, 1);
      }
    }
    if (paramsArray.length > 0) { rtn += '?'; }
    rtn += paramsArray.join('&');
  }
  return rtn;
}

function refreshPageWithoutStatusParam() {
  window.location.assign(new URL(removeParam('status', window.location.href)));
}

export { refreshPageWithoutStatusParam, removeParam };
