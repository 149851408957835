import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import T from '../Translate';


const WebcamTooltip = withStyles((theme) => ({
  arrow: {
    color: (props) => props.color,
  },
  tooltip: {
    backgroundColor: (props) => props.color,
    color: 'white',
    maxWidth: 220,
    padding: 10,
    fontSize: theme.typography.pxToRem(12),
  }
}))(Tooltip);

WebcamTooltip.defaultProps = {
  title: <T>Webcam</T>,
};

export default WebcamTooltip;
