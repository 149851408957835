import React from 'react';
import PropTypes from 'prop-types';
import './PlaceholderList.scss';

const placeholderSurvey = (
  <>
    <div className="ow ow-survey" style={{ background: '#ededed', maxWidth: '100%' }}>
      <div
        className="ow ow-survey-image-container"
        style={{ background: '#c2c2c2' }}
      >
        <div
          className="loading"
          style={{
            zIndex: 2,
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            color: 'red',
          }}
        />
      </div>
      <div className="ow-survey-icon-container">
        <div
          className="loading"
          style={{
            zIndex: 2,
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            color: 'red',
          }}
        />
      </div>
      <div className="ow-survey-info">
        <div className="ow-survey-info-contents">
          <div
            className="ow-reward-container"
            style={{
              background: '#d4d4d4',
              width: '75px',
              height: '32px',
              borderRadius: '15px',
            }}
          >
            <div className="loading ow-reward" style={{ zIndex: 2 }} />
          </div>

          <div
            className="ow-category"
            style={{
              background: '#d4d4d4',
              height: '.97rem',
              width: '120px',
              borderRadius: '5px',
            }}
          >
            <div
              className="loading"
              style={{ zIndex: 2, height: '.97rem', width: '120px' }}
            />
          </div>
          <div
            className="ow-duration"
            style={{
              background: '#d4d4d4',
              height: '.75rem',
              width: '85px',
              marginTop: '16px',
              borderRadius: '5px',
            }}
          >
            <div
              className="loading"
              style={{ zIndex: 2, height: '.75rem', width: '85px' }}
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

const placeholders = [
  placeholderSurvey,
  placeholderSurvey,
  placeholderSurvey,
  placeholderSurvey,
  placeholderSurvey,
  placeholderSurvey,
  placeholderSurvey,
];

function PlaceholderList({ loaded }) {
  return (
    !loaded && (
      <>
        <div className="ow-toggle-button-container">
          <div className="placeholder-button" />
          <div className="placeholder-button" />
        </div>
        <div className="ow-survey-listing">
          {placeholders.map((placeholder, index) => (
            <div key={index} style={{ maxWidth: '100%' }}>{placeholder}</div>/* eslint-disable-line react/no-array-index-key */
          ))}
        </div>
      </>
    )
  );
}

PlaceholderList.propTypes = {
  loaded: PropTypes.bool,
};

PlaceholderList.defaultProps = {
  loaded: false,
};

export default PlaceholderList;
