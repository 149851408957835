import paramsFromScriptSrcURL from './UrlHelper';

const ALLOWED_POSITIONS = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
const MAX_SURVEYS_ALLOWED = 20;

function getToastPosition() {
  const positionSetting = paramsFromScriptSrcURL().notification_position;

  if (ALLOWED_POSITIONS.includes(positionSetting)) {
    return positionSetting;
  }

  return 'top-right';
}

function getHideFilterButtons() {
  const hideFilterButtons = paramsFromScriptSrcURL().hide_filter_buttons;

  if (hideFilterButtons === 'true') {
    return true;
  }

  return false;
}

function isValidInteger(str) {
  const surveyNumber = Math.floor(Number(str));
  return surveyNumber > 0 && surveyNumber <= MAX_SURVEYS_ALLOWED && String(surveyNumber) === str;
}

function getMaxSurveyNumber() {
  const maxSurveyNumber = paramsFromScriptSrcURL().max_survey_number;

  if (maxSurveyNumber && isValidInteger(maxSurveyNumber)) {
    return parseInt(maxSurveyNumber, 10);
  }

  return null;
}

export { getToastPosition, getHideFilterButtons, getMaxSurveyNumber };
