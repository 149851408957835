function paramsFromScriptSrcURL() {
  const scriptTagId = 'qurated-studies';
  const argsAndValues = {};

  const script = document.getElementById(scriptTagId);
  if (!(script && script.src)) { return argsAndValues; }

  const queryStringFromSrcURL = script.src.replace(/^[^?]+\??/, '');
  const queryVars = queryStringFromSrcURL.split('&');

  for (let i = 0; i < queryVars.length; i += 1) {
    const pair = queryVars[i].split('=');
    if (pair.length > 1) {
      argsAndValues[pair[0]] = decodeURI(pair[1]).replace(/\+/g, ' ');
    }
  }

  return argsAndValues;
}

export default paramsFromScriptSrcURL;
