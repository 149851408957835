import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../shared/CloseIcon.svg';
import { ReactComponent as TerminateIcon } from '../../shared/SurveyTerminated.svg';
import { ReactComponent as Congrats } from '../../shared/Congrats.svg';
import T from '../Translate';
import { formatReward } from '../../service/CurrencyFormat';
import './EndSurveyModal.scss';

const SURVEY_STATUS = {
  complete: { s: 'complete', m: 'Survey was completed successfully', t: 'good' },
  terminate: { s: 'terminate', m: 'Survey was not successful this time', t: 'bad' },
  quota_full: { s: 'quota_full', m: 'The survey has enough respondents', t: 'bad' },
  screenout: { s: 'screenout', m: 'Sorry, you do not qualify for the survey this time', t: 'bad' },
  taken: { s: 'taken', m: 'It looks like you have already taken this survey, please try another', t: 'bad' },
  not_available: { s: 'not_available', m: 'This survey is no longer available', t: 'bad' },
  used_link: { s: 'used_link', m: 'It looks like you have already taken this survey, please try another', t: 'bad' },
  expired_link: { s: 'expired_link', m: 'Survey link is no longer valid, please try a different survey', t: 'bad' },
  no_surveys_available: { s: 'no_surveys_available', m: "We couldn't find a survey for you at this time, please try again later", t: 'bad' },
};

class EndSurveyModal extends React.Component {
  constructor(props) {
    super(props);
    this.countryCode = props.countryCode;
    this.handleClose = this.handleClose.bind(this);

    const { surveyStatus, formattedReward } = this.retrieveStatusParamsFromURL();

    if (surveyStatus) {
      this.state = {
        visible: true,
        surveyStatus,
        formattedReward,
      };
    } else {
      this.state = {
        visible: false,
      };
    }
  }

  handleClose() {
    this.setState({ visible: false });
  }

  retrieveStatusParamsFromURL() {
    const url = new URL(window.location.href);
    const responseStatus = url.searchParams.get('status');

    let surveyStatus = null;
    let formattedReward = null;

    if (SURVEY_STATUS[responseStatus]) {
      surveyStatus = SURVEY_STATUS[responseStatus];
    }
    const reward = url.searchParams.get('reward');
    const rewardFormat = url.searchParams.get('currency_format');
    if (reward && rewardFormat) {
      formattedReward = formatReward(rewardFormat, reward, this.countryCode);
    }
    return { surveyStatus, formattedReward };
  }

  render() {
    const { visible, surveyStatus, formattedReward } = this.state;

    return (
      <>
        <Modal
          open={visible}
          onClose={this.handleClose}
          classNames={{ modal: 'ow-end-survey-modal', root: 'ow-end-survey-root' }}
          center
        >
          <div className="ow-end-survey-container">
            <div className="ow-end-survey-header">
              <button
                type="button"
                className="ow-end-survey-close-button"
                onClick={this.handleClose}
              >
                <CloseIcon className="ow-end-survey-close-icon" />
              </button>
            </div>
            <div className="ow-end-survey-content">
              {surveyStatus === SURVEY_STATUS.complete ? (
                <div>
                  <Congrats />
                  <h1 className="ow-end-survey-congratulations"><T>Congratulations</T></h1>
                  {formattedReward && (
                    <>
                      <p className="ow-end-survey-earned"><T>You&apos;ve just earned</T></p>
                      <p className="ow-end-survey-reward">{formattedReward}</p>
                    </>
                  )}
                  <div className="ow-end-survey-footer">
                    <p><T>Your opinion was really appreciated</T></p>
                    <p><strong><T>Keep it up!</T></strong></p>
                  </div>
                </div>
              ) : (
                <div>
                  <TerminateIcon />
                  <h1><T>Hmm?</T></h1>
                  <p><T>For some reason, the survey provider has declined the survey</T></p>
                  <div className="ow-end-survey-footer">
                    <p><T>We really value your opinion</T></p>
                    <p><strong><T>So lets try another one!</T></strong></p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

EndSurveyModal.propTypes = {
  countryCode: PropTypes.string,
};

EndSurveyModal.defaultProps = {
  countryCode: 'USA',
};

export default EndSurveyModal;
