import { useContext } from 'react';
import PropTypes from 'prop-types';
import { LocaleContext } from './LocaleProvider';

function T({ children }) {
  const context = useContext(LocaleContext);

  if (!context) {
    return null;
  }
  const { getTranslation } = context;

  return getTranslation(children);
}

T.propTypes = {
  children: PropTypes.string.isRequired,
};

export default T;
