import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import Survey from './Survey';
import T from './Translate';
import { getAuthToken, shouldRefreshApp } from '../service/Jwt';
import { refreshPageWithoutStatusParam } from '../shared/Refresh';
import { getStudiesHistory } from '../service/Studies';
import './StudiesHistory.scss';

class StudiesHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studies: [],
      errorOccurred: false,
    };
  }

  componentDidMount() {
    this.updateStudiesHistoryState();
  }

  updateStudiesHistoryState() {
    const authtoken = getAuthToken();
    if (shouldRefreshApp(authtoken)) { refreshPageWithoutStatusParam(); }
    trackPromise(getStudiesHistory(authtoken)
      .then((data) => {
        this.setState({ studies: data });
      })
      .catch((error) => {
        if (this.airbrake) {
          // to send error to Airbrake front end error reporting
          this.airbrake.notify(error);
          this.setState({ errorOccurred: true });
        }
      }));
  }

  render() {
    const { studies, errorOccurred } = this.state;

    const studyItems = studies.map((studyAndStatus) => (
      <Survey
        key={studyAndStatus.survey.id}
        category={studyAndStatus.survey.category}
        categoryImage={studyAndStatus.survey.categoryImage}
        formattedReward={studyAndStatus.survey.formattedReward}
        loi={studyAndStatus.survey.loi}
        flags={studyAndStatus.survey.flags}
        url={studyAndStatus.survey.url}
        status={studyAndStatus.status}
        statusUpdatedAt={studyAndStatus.startedAt}
      />
    ));

    if (errorOccurred) {
      return (
        <div />
      );
    }

    if (studyItems.length === 0) {
      return (
        <div />
      );
    }

    return (
      <div>
        <div className="ow-survey-list-heading"><T>RECENTLY FINISHED</T></div>
        <div className="ow-survey-listing ow-faded">{studyItems}</div>
      </div>
    );
  }
}

export default StudiesHistory;
