/* eslint import/no-dynamic-require: "off" */

import React from 'react';
import { Notifier } from '@airbrake/browser';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './components/ErrorBoundary';
import LocaleProvider from './components/LocaleProvider';
import Studies from './components/Studies';
import { getCountryFromAuthToken } from './service/Jwt';
import { localeFromCode } from './service/GetTextHelper';
import EndSurveyModal from './components/EndSurveyModal/EndSurveyModal';
import { getToastPosition, getHideFilterButtons, getMaxSurveyNumber } from './service/WidgetSettings';
import PlaceholderList from './components/PlaceholderList/PlaceholderList';
import './shared/Toast.scss';
import './App.scss';

export const LocaleContext = React.createContext('EN');

class App extends React.Component {
  constructor() {
    super();
    this.airbrake = new Notifier({
      projectId: `${process.env.REACT_APP_AIRBRAKE_PROJECT_ID}`,
      projectKey: `${process.env.REACT_APP_AIRBRAKE_API_KEY}`,
      environment: `${process.env.REACT_APP_AIRBRAKE_ENV_MODE}`,
    }).addFilter((notice) => {
      if (notice.context.environment === 'development') {
        return null;
      }
      return null;
      // return notice;
    });
    this.state = {
      loaded: false,
    };

    // This mocks the injected JWT allowing us to use the app locally without errors
    if (process.env.REACT_APP_DEV_MODE === 'true') {
      const scriptWithAuthToken = document.createElement('script');
      scriptWithAuthToken.id = 'qurated-studies';
      scriptWithAuthToken.src = 'fake-src-url?authtoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJRTUVFX1VTRVJfSUQiLCJuYW1lIjoiSm9obiBEb2UiLCJjb3VudHJ5IjoiRlJBIiwiaWF0IjoxNTE2MjM5MDIyLCJleHAiOjE4MTYyMzkwMjJ9.zDGJIu6xWVL8PXtjgRuBcJjrMMfJlnFk10XoQORvJHk&notification_position=top-left';
      document.body.appendChild(scriptWithAuthToken);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  studiesListLoaded = () => this.setState({ loaded: true });

  render() {
    const countryCode = getCountryFromAuthToken();
    const { loaded } = this.state;
    return (
      <ErrorBoundary notifier={this.airbrake}>
        <LocaleProvider locale={localeFromCode(countryCode)}>
          <ToastContainer
            enableMultiContainer
            containerId="survey-status"
            position={getToastPosition()}
          />
          <EndSurveyModal countryCode={countryCode} />
          <Studies
            onLoaded={this.studiesListLoaded}
            hideFilterButtons={getHideFilterButtons()}
            maxSurveyNumber={getMaxSurveyNumber()}
            loaded={loaded}
          />
          <PlaceholderList loaded={loaded} />
        </LocaleProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
