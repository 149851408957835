import React from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrandSettingsProvider } from "./components/BrandSettings";
import ThemeContext, { fetchTheme } from './shared/theme-context';

var theme = fetchTheme();

ReactDOM.render(
  <div>
    <BrandSettingsProvider>
      <ThemeContext.Provider value={theme}>
        <App />
      </ThemeContext.Provider>
    </BrandSettingsProvider>
  </div>,
  document.getElementById('ow-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
