import { sprintf } from 'sprintf-js';

export function getLocale() {
  const locale = navigator.language || 'en-us';
  return locale.toLowerCase();
}

function getSystemCountry() {
  const locale = getLocale();
  return locale.split('-')[1] || 'us';
}

function getLocaleCurrency(countryCode = getSystemCountry()) {
  switch (countryCode.toLowerCase()) {
    case 'gb':
    case 'gbr':
      return 'GBP';
    case 'ca':
    case 'can':
      return 'CAD';
    case 'au':
    case 'aus':
      return 'AUD';
    case 'de':
    case 'deu':
    case 'fr':
    case 'fra':
      return 'EUR';
    case 'us':
    case 'usa':
    default:
      return 'USD';
  }
}

function formatCurrency(amount, countryCode) {
  const currency = getLocaleCurrency(countryCode);

  const { language } = navigator;
  if (amount < 100) {
    return `${amount}${currency === 'GBP' ? 'p' : '¢'}`;
  }
  return new Intl.NumberFormat(language, { style: 'currency', currency }).format(amount / 100);
}

export function formatReward(currencyFormat, reward, countryCode = getSystemCountry()) {
  if (currencyFormat.includes('.') || currencyFormat.includes(',')) {
    return formatCurrency(reward, countryCode);
  }

  return sprintf(currencyFormat, reward);
}
