import decode from 'jwt-decode';
import paramsFromScriptSrcURL from './UrlHelper';

function getAuthToken() {
  return paramsFromScriptSrcURL().authtoken;
}

function getUserFromAuthToken(authtoken) {
  const decoded = decode(authtoken || getAuthToken());
  return decoded.sub;
}

function getExpiryFromAuthToken(authtoken) {
  const decoded = decode(authtoken || getAuthToken());
  return decoded.exp;
}

function getCountryFromAuthToken(authtoken) {
  const decoded = decode(authtoken || getAuthToken());
  return decoded.country;
}

function getPanelProviderFromAuthToken(authtoken) {
  const decoded = decode(authtoken || getAuthToken());
  return decoded.author;
}

function shouldRefreshApp(authtoken) {
  const exp = getExpiryFromAuthToken(authtoken);
  /* eslint-disable */
  console.log('Token expiry set to: ' + exp)
  /* eslint-enable */
  return (exp !== undefined && exp !== 0 && Math.floor(Date.now() / 1000) > exp);
}

export {
  getAuthToken,
  getUserFromAuthToken,
  getExpiryFromAuthToken,
  getCountryFromAuthToken,
  getPanelProviderFromAuthToken,
  shouldRefreshApp,
};
