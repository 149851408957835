/* eslint global-require: "off" */

import PropTypes from 'prop-types';
import React from 'react';
import './ErrorMessage.scss';
import T from './Translate';

function ErrorMessage({ title, message }) {
  return (
    <div className="ow-error-message-container">
      <div className="ow ow-message">
        <div className="ow ow-message-container">
          <div className="message-container">
            {title && (
            <span className="message-title">
              <T>{title}</T>
            </span>
            )}
            {message && (
            <span className="message-message">
              <T>{message}</T>
            </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  title: '',
  message: '',
};

export default ErrorMessage;
