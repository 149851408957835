import React from 'react';
import paramsFromScriptSrcURL from '../service/UrlHelper';

export const themes = {
  default: {
    survey_box_shadow_color: '#e6ecf0',
    survey_list_bkgnd_color: '#f2f4f6',
    survey_border_radius: '0px',
    survey_info_bkgnd_color: '#ffffff',
    survey_reward_text_color: '#59a1dd',
    survey_reward_bkgnd_color: '#ffffff',
    survey_category_text_color: '#5a5d60',
    survey_duration_text_color: '#90909a',
    button_symbol_color: '#0ACC8B',
  },

  greyscale: {
    survey_box_shadow_color: '#636363',
    survey_list_bkgnd_color: '#333333',
    survey_border_radius: '0px',
    survey_info_bkgnd_color: '#595959',
    survey_reward_text_color: '#1a1a1a',
    survey_reward_bkgnd_color: '#adadad',
    survey_category_text_color: '#e8e8e8',
    survey_duration_text_color: '#e8e8e8',
    button_symbol_color: '#FF8C00',
  },

  blue: {
    survey_box_shadow_color: '#e6ecf0',
    survey_list_bkgnd_color: '#e0f3fa',
    survey_border_radius: '0px',
    survey_info_bkgnd_color: '#33bfff',
    survey_reward_text_color: '#484d4f',
    survey_reward_bkgnd_color: '#adecff',
    survey_category_text_color: '#292e31',
    survey_duration_text_color: '#292e31',
    button_symbol_color: '#B22222',
  },

  red: {
    survey_box_shadow_color: '#e6ecf0',
    survey_list_bkgnd_color: '#fae0e0',
    survey_border_radius: '0px',
    survey_info_bkgnd_color: '#ff3333',
    survey_reward_text_color: '#484d4f',
    survey_reward_bkgnd_color: '#ffadad',
    survey_category_text_color: '#f0f0f0',
    survey_duration_text_color: '#f0f0f0',
    button_symbol_color: '#F0E68C',
  },
  pinchme: {
    survey_box_shadow_color: '#636363',
    survey_list_bkgnd_color: '#333333',
    survey_border_radius: '10px',
    survey_info_bkgnd_color: 'linear-gradient(180deg, #FE75D1 0%, #C07AFF 100%)',
    survey_reward_text_color: 'white',
    survey_reward_bkgnd_color: 'linear-gradient(0deg, rgba(0,120,121,1) 0%, rgba(0,213,215,1) 100%)',
    survey_category_text_color: '#e8e8e8',
    survey_duration_text_color: '#e8e8e8',
    button_symbol_color: '#FE75D1',
  },
};

const ThemeContext = React.createContext(themes.default);

export function fetchTheme() {
  let theme = themes[paramsFromScriptSrcURL().theme];
  if (theme === undefined) { theme = themes.default; }
  return theme;
}

export default ThemeContext;
