import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_PANEL_BRAND_SETTINGS = {
  primary_colour: '#58299C',
  secondary_colour: '#000000',
  title_colour: '#6a6a6a',
  text_colour: 'gray',
};

export const PanelBrandSettingsContext = React.createContext(
  DEFAULT_PANEL_BRAND_SETTINGS,
);

export function BrandSettingsProvider({ children }) {
  const [brandSettings, setBrandSettings] = useState(DEFAULT_PANEL_BRAND_SETTINGS);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [stylesInjected, setStylesInjected] = useState(false);

  useEffect(() => {
    setBrandSettings({
      ...DEFAULT_PANEL_BRAND_SETTINGS,
      ...(window.BRAND_SETTINGS || {}),
    });
    setStylesLoaded(true);
  }, []);

  useEffect(() => {
    if (brandSettings && stylesLoaded && !stylesInjected) {
      const root = document.documentElement;
      root.style.setProperty('--primary-colour', brandSettings.primary_colour);
      root.style.setProperty('--secondary-colour', brandSettings.secondary_colour);
      root.style.setProperty('--title-colour', brandSettings.title_colour);
      root.style.setProperty('--text-colour', brandSettings.text_colour);
      setStylesInjected(true);
    }
  }, [brandSettings, stylesLoaded, stylesInjected]);

  return (
    <PanelBrandSettingsContext.Provider value={brandSettings}>
      {children}
    </PanelBrandSettingsContext.Provider>
  );
}

BrandSettingsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
