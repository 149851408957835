import axios from 'axios';
import Study from '../models/Study';
import paramsFromScriptSrcURL from './UrlHelper';

function getTrackingParams() {
  const nonTrackingParams = [
    'authtoken',
    'notification_position',
    'hide_filter_buttons',
    'max_survey_number',
    'theme',
    'webcam',
  ];
  const trackingParams = {};

  const scriptParams = paramsFromScriptSrcURL();

  Object.keys(scriptParams).forEach((key) => {
    if (!nonTrackingParams.includes(key)) {
      trackingParams[key] = scriptParams[key];
    }
  });

  return trackingParams;
}

function getSurveyParams(webcam, pii, maxStudiesNumber) {
  const params = {
    webcam: webcam.toString(),
    collects_pii: pii.toString(),
    ...getTrackingParams(), // Merge the tracking params
  };

  if (maxStudiesNumber) {
    params.maxStudiesNumber = maxStudiesNumber.toString();
  }

  return new URLSearchParams(params).toString();
}

const getStudies = async (authtoken, webcam = true, pii = true, maxStudiesNumber = null) => {
  const studies = [];

  await axios.get(`${process.env.REACT_APP_QMEE_API_DOMAIN}studies?${getSurveyParams(webcam, pii, maxStudiesNumber)}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  }).then((response) => {
    if (response) {
      response.data.forEach((studyData) => {
        studies.push(new Study(studyData));
      });
    }
  });

  return studies;
};

const getProfileUrl = async (authtoken) => axios.get(`${process.env.REACT_APP_QMEE_API_DOMAIN}v2/profile/url`, {
  headers: { Authorization: `Bearer ${authtoken}` },
});

const getStudiesHistory = async (authtoken) => {
  const studiesWithStatus = [];

  await axios.get(`${process.env.REACT_APP_QMEE_API_DOMAIN}studies_history`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  }).then((response) => {
    if (response) {
      response.data.forEach((studyAndStatusData) => {
        studiesWithStatus.push(studyAndStatusData);
      });
    }
  });
  return studiesWithStatus;
};

export { getStudies, getStudiesHistory, getProfileUrl };
