import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const FilterButton = withStyles({
  root: {
    fontSize: 16,
    backgroundColor: 'white',
    color: '#0ACC8B',
    borderColor: '#0063cc',
    margin: '0px',
    boxShadow: '0 0 0 1px #e6ecf0',
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      zIndex: 10,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

export default FilterButton;
