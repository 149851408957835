import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { ReactComponent as CloseIcon } from '../shared/CloseIcon.svg';

const ProfilePopup = ({ onClose, showProfile, profileUrl }) => {
  const [showLoader, setShowLoader] = useState(true);
  const handleClose = () => {
    onClose();
  };

  const disabledLoader = () => {
    setShowLoader(false);
  };

  useEffect(() => {
    if (!showProfile) setShowLoader(true);
  }, [showProfile]);

  return (
    <div className="ow-profile-iframe-container">
      <Modal
        open={showProfile}
        className="ow-profile-iframe"
        onClick={handleClose}
        classNames={{ modal: 'ow-profile-iframe' }}
      >
        <button
          type="button"
          className="ow-profile-close-button"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
        {showLoader && (
          <div className="ow-profile-loader-container">
            <div className="ow-profile-loader-spinner" />
          </div>
        )}
        {showProfile && (
          <iframe
            onLoad={disabledLoader}
            onError={disabledLoader}
            frameBorder="0"
            src={profileUrl}
            title="profile-iframe"
          />
        )}
      </Modal>
    </div>
  );
};

ProfilePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  showProfile: PropTypes.bool,
  profileUrl: PropTypes.string.isRequired,
};

ProfilePopup.defaultProps = {
  showProfile: false,
};

export default ProfilePopup;
