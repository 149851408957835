import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import T from '../Translate';

const RecontactTooltip = withStyles((theme) => ({
  arrow: {
    color: (props) => props.color,
  },
  tooltip: {
    backgroundColor: (props) => props.color,
    color: 'white',
    maxWidth: 220,
    padding: 10,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

RecontactTooltip.defaultProps = {
  title: <T>Recontact</T>,
};

export default RecontactTooltip;
