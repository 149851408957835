import React from 'react';
import { Notifier } from '@airbrake/browser';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.element.isRequired,
  notifier: PropTypes.objectOf(Notifier),
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const { notifier } = props;
    this.notifier = notifier;
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    if (this.notifier) {
      this.notifier.notify({
        error,
        params: { info },
      });
    }
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      /* eslint max-len: "off" */
      return <h1>Unable to show studies, something went wrong. Please try again later or contact our support.</h1>;
    }

    const { children } = this.props;

    return children;
  }
}

ErrorBoundary.propTypes = propTypes;

ErrorBoundary.defaultProps = {
  notifier: null,
};

export default ErrorBoundary;
